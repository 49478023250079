<template>
  <el-card
    v-loading="isLoading"
    shadow="always"
    :body-style="{ padding: '0px' }"
  >
    <template #header>
      <el-row type="flex" justify="space-between" align="space-between">
        <h4>{{ $route.name }}</h4>
      </el-row>
    </template>
    <el-descriptions border v-loading="isLoadingFilter" class="no-print">
      <template #title>
        <div class="pl-1">Filtrar contas</div>
      </template>
      <el-descriptions-item label="Empresa:" :columns="4">
        <el-select
          clearable
          :loading="!firms"
          value-key="uid"
          v-model="filterOptions.firm_id"
          size="medium"
        >
          <el-option
            v-for="item in Firms"
            :key="item.uid"
            :label="item.name"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="Data:">
        <el-select
          clearable
          value-key="uid"
          v-model="filterOptions.dateColumn"
          size="medium"
        >
          <el-option
            v-for="item in [
              { label: 'Data vencimento', value: 'expires_at' },
              { label: 'Data aquisição', value: 'bought_at' },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="Valor:">
        <el-select
          clearable
          value-key="uid"
          v-model="filterOptions.amountColumn"
          size="medium"
        >
          <el-option
            v-for="item in [
              { label: 'Valor parcela', value: 'amount' },
              { label: 'Valor pago', value: 'amount_paid' },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item label="Ano:">
        <el-date-picker
          v-model="filterOptions.currentYear"
          type="year"
          size="medium"
          format="YYYY"
        >
        </el-date-picker>
      </el-descriptions-item>
      <el-descriptions-item label="Tipo:">
        <el-button-group class="ml-4">
          <el-button
            :type="isButtonTypeSelected('investiment') ? 'primary' : null"
            @click="
              isButtonTypeSelected('investiment')
                ? unselectType('investiment')
                : selectType('investiment')
            "
            >Investimento</el-button
          >
          <el-button
            :type="isButtonTypeSelected('expense') ? 'primary' : null"
            @click="
              isButtonTypeSelected('expense')
                ? unselectType('expense')
                : selectType('expense')
            "
            >Despesa</el-button
          >
          <el-button
            :type="isButtonTypeSelected('cost') ? 'primary' : null"
            @click="
              isButtonTypeSelected('cost')
                ? unselectType('cost')
                : selectType('cost')
            "
            >Custo</el-button
          >
        </el-button-group>
      </el-descriptions-item>
      <el-descriptions-item label="Filtrar:">
        <el-button-group class="ml-4">
          <el-button
            plain
            type="warning"
            @click="() => (filterOptions = {} | filterBills())"
            >Limpar</el-button
          >
          <el-button
            :disabled="isLoadingFilter"
            type="primary"
            @click="() => filterBills()"
            >Adicionar filtro</el-button
          >
        </el-button-group>
      </el-descriptions-item>
    </el-descriptions>

    <el-table
      :data="Cached"
      row-key="id"
      :load="loadNextLevel"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      @expand-change="checkExpand"
      :cell-class-name="
        ({ row, column, rowIndex, columnIndex }) => 'tree-class'
      "
      lazy
    >
      <el-table-column
        label="centro de custo"
        prop="label"
        width="150px"
        fixed="left"
      ></el-table-column>
      <el-table-column
        :label="m"
        :about="i"
        width="150px"
        v-for="(m, i) of AbbreviatedMonths"
        :key="i"
      >
        <template #default="s">
          <div v-if="Sums[s.row.id] && i in Sums[s.row.id]">
            {{ Sums[s.row.id]?.[i] }}
          </div>
          <div v-else class="center-loading">
            <i class="el-icon-loading" :id="s.row.id"></i>
          </div>
        </template>
      </el-table-column>

      <template #append>
        <el-descriptions border :column="13">
          <el-descriptions-item
            label="Despesas:"
            class-name="remove-label"
            min-width="150px"
          ></el-descriptions-item>
          <el-descriptions-item
            v-for="i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
            :key="i"
            min-width="150px"
            label-class-name="remove-label"
            class-name="text-center p-0"
            >{{
              currencyFormatter.format(calcTotalForColumn(i))
            }}</el-descriptions-item
          >
        </el-descriptions>
        <el-descriptions border :column="13">
          <el-descriptions-item
            min-width="150px"
            label="Receitas:"
            class-name="remove-label"
            label-class-name="max-col-13"
          ></el-descriptions-item>
          <el-descriptions-item
            min-width="150px"
            v-for="i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
            :key="i"
            label-class-name="remove-label"
            class-name="max-col-13 text-center"
          >
            <el-popover
              :visible="showAddIncome[i]"
              @update:visible="showAddIncome[i] = $event"
              trigger="click"
              placement="top"
              :width="250"
            >
              <template #reference>
                <div
                  @click="
                    () =>
                      (incomeInput = getIncomeByRef(i)?.amount) |
                      (showAddIncome[i] = true)
                  "
                  style="cursor: pointer"
                >
                  {{
                    currencyFormatter.format(
                      Number(getIncomeByRef(i)?.amount) || 0
                    )
                  }}
                </div>
              </template>
              <div>
                <base-input
                  v-model="incomeInput"
                  label="R$"
                  v-on:keyup.enter="
                    () =>
                      saveIncome({
                        ...getIncomeByRef(i),
                        amount: incomeInput,
                        ref_year: filterOptions.currentYear.getFullYear(),
                        ref_month: i,
                        firm_id: filterOptions.firm_id || '',
                      }) | (showAddIncome[i] = false)
                  "
                  type="money"
                ></base-input>
              </div>
            </el-popover>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions border :column="13">
          <el-descriptions-item
            label="Resultado:"
            class-name="remove-label"
            min-width="150px"
          ></el-descriptions-item>
          <el-descriptions-item
            min-width="150px"
            v-for="i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
            :key="i"
            label-class-name="remove-label"
            :class-name="{
              'max-col-13': true,
              'text-center': true,
              'upper-amount':
                Number(getIncomeByRef(i)?.amount || 0) - calcTotalForColumn(i) >
                0,
              'lower-amount':
                Number(getIncomeByRef(i)?.amount || 0) - calcTotalForColumn(i) <
                0,
            }"
            >{{
              currencyFormatter.format(
                Math.abs(
                  Number(getIncomeByRef(i)?.amount || 0) - calcTotalForColumn(i)
                )
              )
            }}</el-descriptions-item
          >
        </el-descriptions>
      </template>
    </el-table>
  </el-card>
</template>
<script>
import {
  generateDotStringFromCost,
  convertKeysToLabels,
  generateObjectFromDotString,
} from "../utils/functions";
import IncomeService from "../services/incomes";
import BaseInput from "../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import OrderService from "../services/orders";

export default {
  name: "AccountabilityPage",
  data() {
    return {
      costs: null,
      compiledCosts: null,
      isLoading: true,
      isLoadingFilter: false,
      showAddIncome: [],
      firmId: "cbdde4f6-0574-44cb-b1fc-c48c03a14e22",
      firmData: [],
      incomes: null,
      incomeInput: 0,
      filterOptions: {
        currentYear: new Date(),
        dateColumn: "expires_at",
        amountColumn: "amount",
      },
      firms: null,
      sums: {},
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    };
  },
  components: { BaseInput },
  computed: {
    AbbreviatedMonths() {
      return [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
    },
    Firms() {
      return this.firms || [];
    },
    Costs() {
      return this.costs;
    },
    Cached() {
      return this.compiledCosts || [];
    },
    Sums() {
      return this.sums || {};
    },
  },
  watch: {
    async costs(v) {
      if (v?.length) {
        for (let x of v) {
          x.dotString = generateDotStringFromCost(x);
        }

        this.compiledCosts = this.getCostsTree(v);

        await this.loadFirstSums();
        this.loadTotalSells();
      }
    },
  },
  mounted() {
    this.fetchCosts();
    this.fetchIncomes();
    this.fetchFirms();
  },
  methods: {
    async loadTotalSells() {
      const currentYear =
        this.filterOptions.currentYear?.getFullYear() ||
        new Date().getFullYear();
      const months = Array.from({ length: 12 });
      const self = this;

      this.firmData = months;

      months.forEach(async (_, month) => {
        const firstDay = new Date(currentYear, month, 1);
        const lastDay = new Date(currentYear, month + 1, 0, 23, 59, 999);

        const { orders: monthlyOrders } = await OrderService().index({
          delivered_at_start: firstDay,
          delivered_at_end: lastDay,
          status: "completed,paid,finalized",
        });

        self.firmData[month] = monthlyOrders.reduce(
          (total, order) => total + Number(order.total_amount),
          0
        );
      });
    },
    getIncomeByRef(month) {
      if (this.filterOptions.firm_id === this.firmId) {
        return { amount: this.firmData[month] || 0 };
      }
      const income = this.incomes?.find((e) => e.ref_month === month) || {
        amount: 0,
      };
      return income;
    },
    fetchFirms() {
      const url = new URL(`${this.$store.state.apiUrl}firms`);

      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.firms = json;
        });
    },
    async loadFirstSums() {
      const queries = [];
      this.isLoadingFilter = true;
      for (let c of this.compiledCosts)
        queries.push(this.fetchSums(c, c.label));

      this.compiledCosts?.forEach((c) => (c.isVisible = true));
      await Promise.allSettled(queries);
      this.isLoadingFilter = false;
      return;
    },
    isButtonTypeSelected(type) {
      return this.filterOptions?.costType?.includes(type);
    },
    selectType(type) {
      if (!Array.isArray(this.filterOptions.costType))
        this.filterOptions.costType = [];

      this.filterOptions.costType.push(type);
    },
    unselectType(type) {
      if (this.isButtonTypeSelected(type))
        this.filterOptions.costType = this.filterOptions.costType?.filter(
          (t) => t != type
        );
    },
    checkExpand(row, expand) {
      if (expand) {
        this.hideValueInEntireRow(row);
        this.sums[row.id].isLoaded = false;
      } else {
        this.showEntireRow(row);
        this.sums[row.id].isLoaded = true;
      }
    },
    async loadNextLevel(row, tree, resolve) {
      resolve(row.children);
      for (let c in row.children) {
        const _ = row.children[c];
        const root = _.dotString;
        const [category, subcategory, name, entity] = root.split(".");

        await this.fetchSums(_, category, subcategory, name, entity);
      }
    },
    hideValueInEntireRow(row) {
      const _ = this.sums[row.id];
      for (let c = 0; c < 12; c++) {
        _[`_${c}`] = _[c];
        _[c] = "";
      }

      row?.children?.forEach((r) => (r.isVisible = true));
    },
    getAllLoadedRows(rows) {
      return rows
        ?.map((row) => this.getChildren(row).flat(4))
        ?.flat(4)
        ?.filter((r) => r.id in this.sums && this.sums[r.id].isLoaded);
    },
    getChildren(row) {
      if (row?.children?.length) {
        return [row, ...(row.children?.map((r) => this.getChildren(r)) || [])];
      }
      return [row];
    },
    async filterBills() {
      this.isLoadingFilter = true;

      this.fetchIncomes();

      const rows = this.getAllLoadedRows(this.Cached);

      rows.forEach((r) => this.clearEntireRow(r));

      const visibleRows = rows?.filter((r) => r.isVisible);
      const hiddenRows = rows?.filter((r) => !r.isVisible);
      const queries = [];

      for (let v of visibleRows) {
        const [category, subcategory, name, entity] = v?.dotString.split(".");
        queries.push(this.fetchSums(v, category, subcategory, name, entity));
      }

      for (let v of hiddenRows) {
        const [category, subcategory, name, entity] = v?.dotString.split(".");
        queries.push(this.fetchSums(v, category, subcategory, name, entity));
      }

      await Promise.allSettled(queries);

      this.loadTotalSells();

      this.isLoadingFilter = false;
    },
    async fetchIncomes() {
      const { incomes } = await IncomeService().index({
        ref_year: this.filterOptions.currentYear.getFullYear(),
        firm_id: this.filterOptions?.firm_id || "",
      });

      if (incomes) this.incomes = incomes;
    },
    async saveIncome(income) {
      if (income) {
        "uid" in income
          ? await this.updateIncome(income)
          : await this.createIncome(income);

        this.fetchIncomes();
      }
    },
    async createIncome(inc) {
      const { income } = await IncomeService().create(inc);

      if (income)
        ElNotification.success({ title: "Receita cadastrada com sucesso" });
      else
        ElNotification.error({ title: "Não foi possível cadastrar a receita" });
    },
    async updateIncome(inc) {
      const { income } = await IncomeService(inc.uid).update(inc);

      if (income)
        ElNotification.success({ title: "Receita atualizada com sucesso" });
      else
        ElNotification.error({ title: "Não foi possível atualizar a receita" });
    },
    clearEntireRow(row) {
      if (row.id in this.sums) {
        const _ = this.sums[row.id];
        for (let c = 0; c < 12; c++) {
          delete _[`_${c}`];
          delete _[c];
        }
      }
    },
    showEntireRow(row) {
      if (row?.id in this.sums) {
        const _ = this.sums[row.id];
        for (let d = 0; d < 12; d++) _[d] = _[`_${d}`];

        row?.children?.forEach((r) => (r.isVisible = false));
      }
    },
    calcTotalForColumn(col) {
      const rows = this.getAllLoadedRows(this.Cached)?.filter(
        (r) => r.isVisible
      );

      return (
        rows?.reduce((t, r) => (t += this.sums[r.id][`val_${col}`] || 0), 0) ||
        0
      );
    },
    async fetchSums(row, category, subcategory, name, entity) {
      const result = await this.sumBillsByCost(
        category,
        subcategory,
        name,
        entity
      );

      const _ = {};

      if (Array.isArray(result)) {
        for (let r of result) {
          const { total, month } = r;
          _[month - 1] = this.currencyFormatter.format(Number(total) || 0);
          _[`val_${month - 1}`] = Number(total);
        }

        _.isLoaded = true;

        this.sums[row.id] = _;
      }

      this.zeroEmptyColumns(row);

      //this.$forceUpdate();
      return Promise.resolve();
    },
    getCostsTree() {
      return convertKeysToLabels(
        this.Costs?.map((c) => c.dotString)?.reduce(
          (t, c) => generateObjectFromDotString(t, c),
          {}
        ) || {}
      );
    },
    getCostFromDotString(dotString) {
      return this.Costs.find((c) => c.dotString === dotString);
    },
    zeroEmptyColumns(row) {
      if (row.id in this.sums) {
        const _ = this.sums[row.id];
        for (let c = 0; c < 12; c++) {
          if (!(c in _)) _[c] = this.currencyFormatter.format(0);
        }
      }
    },
    generateDotStringFromNode(node) {
      let parent = node.parent;
      let _ = node.data.label;
      while (parent) {
        if ("label" in parent?.data) _ = `${parent?.data?.label}.${_}`;
        parent = parent.parent;
      }
      return _;
    },
    getCostsObjectTree() {
      const tree = this.getCostsTree();
      const _ = [];

      for (let p in tree) {
        _.push({ label: p, children: convertKeysToLabels(tree[p]) });
      }

      return _.flat();
    },
    fetchCosts() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}costs`);
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.costs = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    sumBillsByCost(category = "", subcategory = "", name = "", entity = "") {
      const url = new URL(`${this.$store.state.apiUrl}costs/bills/sum`);
      url.search = new URLSearchParams({
        category,
        subcategory,
        name,
        entity,
        year: this.filterOptions?.currentYear?.getFullYear(),
        ...(this.filterOptions || {}),
      });
      return fetch(url, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((response) => {
        if (response.status === 200) return response.json();
        else throw response.json();
      });
    },
  },
};
</script>
<style>
.tree-class .cell {
  display: flex;
  justify-content: left;
  background-color: white;
}
</style>
<style scoped>
@media print {
  @page {
    margin: 5mm;
  }

  .el-descriptions >>> max-col-13 {
    max-width: 7.69px !important;
    min-width: unset !important;
  }

  .no-print {
    display: none;
  }

  .el-table >>> .el-table__body {
    width: unset !important;
  }

  .el-table >>> .el-table__header {
    width: unset !important;
  }

  .el-table >>> el-table__fixed {
    font-size: 9px !important;
    width: unset !important;
  }

  .el-table >>> .el-table__indent {
    padding-left: 2px !important;
  }

  .el-table >>> .el-table__fixed {
    position: unset !important;
    height: unset !important;
  }

  .el-table >>> .el-table__fixed >>> td {
    font-size: 9px !important;
  }

  .el-table >>> .el-table__expand-icon {
    display: none !important;
  }

  .cell div {
    font-size: 9px !important;
  }

  .el-descriptions >>> td {
    font-size: 9px !important;
    min-width: unset !important;
  }
}

.center-loading {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.el-descriptions .el-descriptions__title {
  padding-left: 16px;
}

.pl-1 {
  padding-left: 10px;
  padding-top: 8px;
}

.p-0 {
  padding: 0px !important;
}
</style>

<style>
.el-table__append-wrapper {
  overflow: visible !important;
}

.remove-label {
  display: none;
}

.max-col-13 {
  max-width: 7.692%;
}

.reduce-size {
  font-size: 10px !important;
}

.upper-amount {
  color: green;
}

.lower-amount {
  color: red;
}
</style>
